import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Container } from '../components/styled';

const AnkaufPage = ({ data }) => (
  <Layout>
    <Container dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
  </Layout>
);

export default AnkaufPage;

export const pageQuery = graphql`
  query singlePageQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
